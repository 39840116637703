/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
/* eslint-disable prettier/prettier */
import React, { Component, Suspense, useEffect } from 'react'
import { BrowserRouter, HashRouter, Route, Routes } from 'react-router-dom'
import './scss/style.scss'

// import '@coreui/coreui/css/coreui.css';

import { useDispatch, useSelector } from 'react-redux'
import Page500 from './views/pages/page500/Page500'
import { set } from './Redux/reducers/changeStateSlice'

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const DefaultLayout = React.lazy(() => import('./layout/DefaultLayout'))

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'))
const Register = React.lazy(() => import('./views/pages/register/Register'))
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'))

const App = () => {
  const dispatch = useDispatch()
  const data = useSelector((state) => state)
  // console.log("data for index",data);

  useEffect(() => {
   
    if (localStorage.getItem('access_token')) {
      
      dispatch(set({ isLoggedIn: true }))
    }
  }, [localStorage])

  return (
    <BrowserRouter>
      <Suspense fallback={loading}>
        <Routes>
        
          {
            data.changeState.isLoggedIn ? <Route path="*" name="Home" element={<DefaultLayout />} /> : (
              <Route exact path="*" name="Login Page" element={<Login />} />
            )}
          <Route exact path="/404" name="Page 404" element={<Page404 />} />
          <Route exact path="/500" name="Page 500" element={<Page500 />} />
        </Routes>
      </Suspense>
    </BrowserRouter>
  )
}

export default App
