/* eslint-disable prettier/prettier */

// import { createStore, } from 'redux';



// const initialState = {
//   sidebarShow: true,
//   isLoggedIn: false,
// }

// const changeState = (state = initialState, { type, ...rest }) => {
//   switch (type) {
//     case 'set':
//       return { ...state, ...rest }
//     default:
//       return state
//   }
// }

// const store = createStore(changeState,)
// export default store


// store.js
import { configureStore } from '@reduxjs/toolkit';
import changeStateSlice from './Redux/reducers/changeStateSlice';
import { api } from './Redux/api/apiSlice';
// import changeStateReducer from './reducers/changeStateSlice'; // Assuming you'll define reducers using createSlice


const store = configureStore({
  reducer: {
    // Add your reducers here
   [api.reducerPath] : api.reducer,
    changeState: changeStateSlice,
    // Add other reducers as needed
  },
  middleware : (getDefaultMiddleware) => 
  getDefaultMiddleware().concat(api.middleware)
});

export default store;
