/* eslint-disable prettier/prettier */
// reducers/changeStateSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  sidebarShow: true,
  isLoggedIn: false,
};

const changeStateSlice = createSlice({
  name: 'changeState',
  initialState,
  reducers: {
    set: (state, action) => {
      // console.log("action", action.payload);
      return { ...state, ...action.payload };
    },
  },
});

export const { set } = changeStateSlice.actions;

export default changeStateSlice.reducer;
