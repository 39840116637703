/* eslint-disable prettier/prettier */
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const api = createApi({
    reducerPath: "api",
    baseQuery: fetchBaseQuery({
      baseUrl: "https://solar-health-server.labontest.tech",
     
    }),
    tagTypes: ["Specialist","Image","Unverified","Verified"],
    endpoints: () => ({}),
  });